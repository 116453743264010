<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('section_operations')" :isNewButton="checkPermission('section_store')" :isColumns="true"
                    :is-import-button="checkPermission('section_import')" @import-button-click="importData"
                    @new-button-click="createFormModalShow()" @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ br2space(column.label) }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('sections')" :isNewButton="checkPermission('section_store')" :isColumns="true"
                    :is-import-button="checkPermission('section_import')" @import-button-click="importData"
                    @new-button-click="createFormModalShow()" @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ br2space(column.label) }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>


            <!-- Datatable -->
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear"
                :exportExcel="checkPermission('section_excelexport')" @exportExcel="exportExcel">
                <b-row>
                    <b-col sm="12" md="6" lg="3">
                        <b-form-group :label="$t('id')">
                            <b-form-input type="number" v-model="datatable.queryParams.filter.id"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('period')">
                            <semesters-selectbox v-model="datatable.queryParams.filter.semester_id"></semesters-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="3">
                        <b-form-group :label="$t('course_code')">
                            <b-form-input v-model="datatable.queryParams.filter.course_code_like"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('course_name')">
                            <b-form-input v-model="datatable.queryParams.filter.course_name_like"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"></faculty-selectbox>
                        </b-form-group>
                        <b-form-group :label="$t('department')">
                            <department-selectbox v-model="datatable.queryParams.filter.department_code"
                                :faculty_code="datatable.queryParams.filter.faculty_code"></department-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6">
                        <b-form-group :label="$t('program')">
                            <program-selectbox v-model="datatable.queryParams.filter.program_code"
                                :department_code="datatable.queryParams.filter.department_code"></program-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="3">
                        <b-form-group :label="$t('section')">
                            <b-form-input v-model="datatable.queryParams.filter.section" type="number" :min="1"
                                :max="999999999" v-check-min-max-value></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('quota')">
                            <b-form-input v-model="datatable.queryParams.filter.quota" type="number" :min="0"
                                :max="999999999" v-check-min-max-value></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="3">
                        <b-form-group :label="$t('teaching_staff')">
                            <staff-auto-complete v-model="datatable.queryParams.filter.instructor_id"></staff-auto-complete>
                        </b-form-group>
                        <b-form-group :label="$t('status')">
                            <status-selectbox v-model="datatable.queryParams.filter.status" :placeholder="$t('select')"
                                :setOptions="[{ text: $t('open'), value: 'a' }, { text: $t('close'), value: 'p' }]"></status-selectbox>
                        </b-form-group>
                    </b-col>

                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                :total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
                @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange" v-show="datatable.showTable">
            </datatable>

            <!-- Create Modal -->
            <CommonModal ref="createModal" size="xxl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('New Section').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <create-form @createFormSuccess="createFormSuccess($emit)" :key="createFormKey"
                        v-if="formProcess == 'create'" />
                </template>
            </CommonModal>

            <!-- Update Modal -->
            <CommonModal ref="updateModal" size="xxl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('edit') }}
                </template>
                <template v-slot:CommonModalContent>
                    <update-form :formId="formId" @updateFormSuccess="updateFormSuccess($emit)" :key="updateFormKey"
                        v-if="formProcess == 'update'" />
                </template>
            </CommonModal>

            <!-- Instructor Modal -->
            <CommonModal ref="instructorModal" size="lg" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('additional_lecturers') }}
                </template>
                <template v-slot:CommonModalContent>
                    <instructor-index :formId="formId" :key="detailFormKey" v-if="formProcess == 'instructor'">
                    </instructor-index>
                </template>
            </CommonModal>

            <!-- Detail Modal -->
            <CommonModal ref="detailModal" size="xxl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('details') }}
                </template>
                <template v-slot:CommonModalContent>
                    <detail-index :formId="formId" :key="detailFormKey" v-if="formProcess == 'detail'">
                    </detail-index>
                </template>
            </CommonModal>

            <!-- Student List Modal -->
            <CommonModal ref="studentListModal" size="xxl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('list_of_student_taking_the_course') }}
                </template>
                <template v-slot:CommonModalContent>
                    <student-list-index :formId="formId" :key="studentListFormKey" v-if="formProcess == 'studentList'">
                    </student-list-index>
                </template>
            </CommonModal>

            <CommonModal ref="importModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('import') }}
                </template>
                <template v-slot:CommonModalContent>
                    <import-excel :importMethod="importAsExcel" @successProcess="closeModal" />
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>

<script>
import AppLayout from '@/layouts/AppLayout'
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
import DatatableFilter from '@/components/datatable/DatatableFilter';
import Datatable from '@/components/datatable/Datatable';
import CommonModal from '@/components/elements/CommonModal';
import CreateForm from './CreateForm';
import UpdateForm from './UpdateForm'
import DetailIndex from './detail/Index'
import InstructorIndex from './instructor/Index'
import StudentListIndex from './student-list/Index'
import DepartmentSelectbox from '@/components/interactive-fields/DepartmentSelectbox';
import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox';
import ProgramSelectbox from '@/components/interactive-fields/ProgramSelectbox';
import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox';
import StaffAutoComplete from '@/components/interactive-fields/StaffAutoComplete';
import StatusSelectbox from '@/components/interactive-fields/StatusSelectbox';
import qs from 'qs'

// Services
import SectionService from '@/services/SectionService'
import ImportExcel from "./ImportExcel.vue";

// Component
export default {
    components: {
        ImportExcel,
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        CommonModal,
        CreateForm,
        UpdateForm,
        DetailIndex,
        InstructorIndex,
        StudentListIndex,
        DepartmentSelectbox,
        FacultySelectbox,
        ProgramSelectbox,
        SemestersSelectbox,
        StaffAutoComplete,
        StatusSelectbox

    },
    metaInfo() {
        return {
            title: this.toUpperCase('section_operations')
        }
    },
    data() {
        return {
            // Datatable
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.toUpperCase('edit'),
                                class: 'ri-search-eye-line align-middle top-minus-1 mr-3 text-muted',
                                permission: "section_update",
                                callback: (row) => {
                                    this.updateFormModalShow(row.id)
                                },
                                show: function (row) {
                                    return true;
                                }
                            },
                            {
                                text: this.toUpperCase('detail'),
                                class: 'ri-list-settings-line align-middle top-minus-1 mr-3 text-muted',
                                permission: "sectiondetail_show",
                                callback: (row) => {
                                    this.detailFormModalShow(row.id)
                                },
                                show: function (row) {
                                    return true;
                                }
                            },
                            {
                                text: this.toUpperCase('additional_lecturer'),
                                class: 'ri-user-add-line align-middle top-minus-1 mr-3 text-muted',
                                permission: "section_instructor",
                                callback: (row) => {
                                    this.instructorFormModalShow(row.id)
                                },
                                show: function (row) {
                                    return true;
                                }
                            },
                            {
                                text: this.toUpperCase('list_of_student_taking_the_course'),
                                class: 'ri-contacts-book-2-line align-middle top-minus-1 mr-3 text-muted',
                                permission: "section_students",
                                callback: (row) => {
                                    this.studentsListModalShow(row.id)
                                },
                                show: function (row) {
                                    return true;
                                }
                            },
                            {
                                text: this.toUpperCase('delete'),
                                class: 'ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted',
                                permission: "section_delete",
                                callback: (row) => {
                                    this.deleteForm(row.id)
                                },
                                show: function (row) {
                                    return true;
                                }
                            }
                        ]
                    },
                    {
                        label: this.toUpperCase('id'),
                        field: 'id',
                        hidden: true
                    },
                    {
                        label: this.toUpperCase('academic_year'),
                        field: 'academic_year',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('period'),
                        field: 'semester',
                        hidden: false,
                        sortable: false,
                        formatFn: (value) => {
                            return this.getSemester(value);
                        }
                    },
                    {
                        label: this.toUpperCase('faculty'),
                        field: 'faculty_name',
                        hidden: true,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('program'),
                        field: 'program_name',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('course_id'),
                        field: 'course_id',
                        hidden: true,
                        sortable: false
                    },
                    {
                        label: this.spaceToBR(this.toUpperCase('course_code')),
                        field: 'course_code',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.spaceToBR(this.toUpperCase('course_name')),
                        field: 'course_name',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.spaceToBR(this.toUpperCase('course_type')),
                        field: 'course_type_name',
                        hidden: true,
                        sortable: false
                    },
                    {
                        label: this.spaceToBR(this.toUpperCase('section_type')),
                        field: 'type',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                        hidden: true,
                        sortable: false,
                        formatFn: (value) => {
                            return this.getSectionType(value);
                        }
                    },
                    {
                        label: this.toUpperCase('section'),
                        field: 'section',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.spaceToBR(this.toUpperCase('student_count')),
                        field: 'student_count',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('quota'),
                        field: 'quota',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.spaceToBR(this.toUpperCase('teaching_staff_no')),
                        field: 'instructor_id',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.spaceToBR(this.toUpperCase('teaching_staff')),
                        field: 'instructor_name',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.spaceToBR(this.toUpperCase('contract_type')),
                        field: 'contract_type_name',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('status'),
                        field: 'status',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                        hidden: false,
                        sortable: false,
                        formatFn: (value) => {
                            return value === 'a' ? this.$t('open') : this.$t('closed')
                        },
                    }
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    page: 1,
                    limit: 10,
                    order: '-id'
                }
            },

            // Form
            formId: null,
            formProcess: null,

            // Component Keys
            createFormKey: 'createFormKey-1',
            updateFormKey: 'updateFormKey-1',
            detailFormKey: 'detailFormKey-1',
            instructorFormKey: 'instructorFormKey-1',
            studentListFormKey: 'studentListFormKey-1'
        }
    },
    created() {
        this.filterSet()
        this.formClear()
    },
    methods: {
        // Datatable
        filterSet() {
            this.datatable.queryParams.filter = {
                id: null,
                semester_id: null,
                faculty_code: null,
                department_code: null,
                program_code: null,
                course_code: null,
                course_name: null,
                type: null,
                section: null,
                quota: null,
                instructor_id: null,
                status: null
            }
        },
        filterClear() {
            this.filterSet()
            this.getRows();
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            return SectionService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },

        // Clear
        formClear() {
            this.formId = null;
            this.formProcess = null;
        },

        // Create
        createFormModalShow() {
            this.formClear();
            this.formProcess = 'create';
            this.createFormKey = 'createFormKey-' + Math.floor((Math.random() * 1000) + 1);
            this.$refs.createModal.$refs.commonModal.show();
        },
        createFormSuccess(result) {
            this.getRows();
            this.$refs.createModal.$refs.commonModal.hide()
        },

        // Update
        updateFormModalShow(id) {
            this.formId = id;
            this.formProcess = 'update';
            this.createUpdateFormKey = 'updateFormKey-' + Math.floor((Math.random() * 1000) + 1);
            this.$refs.updateModal.$refs.commonModal.show();
        },
        updateFormSuccess() {
            this.getRows();
            this.formProcess = null;
            this.$refs.updateModal.$refs.commonModal.hide()
        },

        // Detail
        detailFormModalShow(id) {
            this.formId = id;
            this.formProcess = 'detail';
            this.detailFormKey = 'detailFormKey-' + Math.floor((Math.random() * 1000) + 1);
            this.$refs.detailModal.$refs.commonModal.show();
        },

        // Instructor
        instructorFormModalShow(id) {
            this.formId = id;
            this.formProcess = 'instructor';
            this.instructorFormKey = 'instructorFormKey-' + Math.floor((Math.random() * 1000) + 1);
            this.$refs.instructorModal.$refs.commonModal.show();
        },

        // Student List
        studentsListModalShow(id) {
            this.formId = id;
            this.formProcess = 'studentList';
            this.studentListFormKey = 'studentListFormKey-' + Math.floor((Math.random() * 1000) + 1);
            this.$refs.studentListModal.$refs.commonModal.show();
        },

        // Delete
        deleteForm(id) {
            this.$swal.fire({
                text: this.toUpperCase('are_you_sure_to_delete'),
                showCancelButton: true,
                confirmButtonText: this.toUpperCase('yes'),
                cancelButtonText: this.toUpperCase('no')
            }).then((result) => {
                if (result.isConfirmed) {
                    SectionService.del(id)
                        .then(response => {
                            this.$toast.success(this.$t('api.' + response.data.message));
                            this.getRows();
                            this.formClear();
                        })
                        .catch(error => {
                            this.$toast.error(this.$t('api.' + error.data.message));
                        })
                }
            })
        },

        exportExcel() {
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            return SectionService.exportExcel(config)
                .then((response) => {
                    this._downloadFile(response, 'sections.xlsx')
                })
                .catch(e => {
                });
        },
        importData() {
            this.formProcess = 'import'
            this.$refs.importModal.$refs.commonModal.show()
        },
        closeModal() {
            this.formProcess = null
            this.$refs.importModal.$refs.commonModal.hide()
            this.getRows()
        },
        importAsExcel(isValid, defineForm, form) {

        },
    }
}
</script>
