<template>
    <div class="max">
        <div class="spinner over" v-show="formLoading">
            <div class="d-flex justify-content-center">
                <b-spinner label="Spinning" class="text-primary"></b-spinner>
            </div>
        </div>
        <b-row v-show="!formLoading">
            <b-col cols="12">
                <ValidationObserver ref="formModalValidate">
                <div class="d-flex">
                    <div class="flex-grow-1 mr-2">
                            <b-form-group :label="$t('additional_lecturer')">
                                <ValidationProvider name="instructor_id" rules="required" v-slot="{valid, errors}">
                                    <staff-auto-complete v-model="formData.instructor_id"
                                                         :validate-error="errors[0]"
                                    ></staff-auto-complete>
                                </ValidationProvider>
                            </b-form-group>
                    </div>
                    <div class="width-200 mr-2">
                            <b-form-group :label="$t('week')">
                                <ValidationProvider name="week" rules="required" v-slot="{valid, errors}">
                                    <b-form-group>
                                        <b-form-input
                                            type="number"
                                            v-model="formData.week"
                                            :min="1"
                                            :max="32"
                                            v-check-min-max-value
                                        ></b-form-input>
                                        <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-form-group>
                    </div>
                    <div>
                        <label>&nbsp;</label>
                        <b-button variant="primary"
                                  @click="createForm"
                                  :disabled="formProcess">
                            {{ $t('add') }}
                        </b-button>
                    </div>

                </div>
                </ValidationObserver>
            </b-col>
            <b-col cols="12">
                <label>{{ $t('additional_lecturers') }}</label>
                <div v-if="instructors && instructors.length > 0">
                    <b-table bordered striped hover
                             :fields="instructorsFields"
                             :items="instructors"
                    >
                        <template #cell(type)="data">
                            {{ data.item.type_name }}
                        </template>
                        <template #cell(delete)="data">
                            <b-button variant="danger" size="sm" @click="deleteForm(data.item.id)">
                                {{ $t('delete').toUpper() }}
                            </b-button>
                        </template>
                    </b-table>
                </div>
                <div v-else>
                    <div class="border p-4 rounded-sm">
                        {{ $t('no_result') }}
                    </div>
                </div>
            </b-col>
        </b-row>

    </div>
</template>

<script>
    // Components
    import StaffAutoComplete from "@/components/interactive-fields/StaffAutoComplete";

    // Services
    import SectionService from "@/services/SectionService"
    import SectionInstructorService from "@/services/SectionInstructorService";

    // Other
    import {ValidationProvider, ValidationObserver} from "vee-validate"
    import qs from "qs";

    export default {
        components: {
            StaffAutoComplete,

            ValidationProvider,
            ValidationObserver,
        },
        props: {
            formId: {
                type: Number
            }
        },
        data() {
            return {
                section: {},

                instructors: {},
                instructorsFields: [
                    {
                        key: 'instructor_name',
                        label: this.$t('name').toUpper(),
                        sortable: true
                    },
                    {
                        key: 'instructor_surname',
                        label: this.$t('surname').toUpper(),
                        sortable: true
                    },
                    {
                        key: 'week',
                        label: this.$t('week'),
                        sortable: true
                    },
                    {
                        key: 'delete',
                        label: '',
                        sortable: true,
                        tdClass:'width-50 text-center'
                    }
                ],
                formData: {
                    instructor_id: null,
                    week: 1
                },
                formLoading: false,
                formProcess: false
            }
        },
        created() {
            if (this.formId) {
                this.getSection(this.formId);
            }
        },
        methods: {
            formDataClear() {
                this.formData = {
                    instructor_id: null,
                }
            },

            getSection(id) {
                this.formLoading = true
                SectionService.get(id)
                              .then(response => {
                                  this.section = response.data.data;
                              })
                              .then(() => {
                                  this.getInstructors(this.section.id);
                              })
                              .catch(e => {
                                  this.$router.push('/404')
                              })
                              .finally(() => {
                                  this.formLoading = false;
                              })
            },

            getInstructors(id) {
                const config = {
                    params: {
                        filter: {
                            section_id: id
                        },
                        limit: -1,
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };
                SectionInstructorService.getAll(config)
                                        .then(response => {
                                            this.instructors = response.data.data;
                                        })
            },

            // Create
            async createForm() {
                if(this.checkPermission('sectioninstructor_store')){
                    const isValid = await this.$refs.formModalValidate.validate();
                    if (isValid) {
                        this.formProcess = true;

                        let formData = {
                            section_id: this.section.id,
                            ...this.formData
                        }

                        SectionInstructorService.store(formData)
                                                .then(response => {
                                                    this.formDataClear();
                                                    this.$toast.success(this.$t('api.' + response.data.message));
                                                    this.$refs.formModalValidate.reset();

                                                    this.getInstructors(this.section.id);
                                                })
                                                .catch(e => {
                                                    if (e.status == 422) {
                                                        if (e.data.errors.instructor_id) this.$refs.formModalValidate.errors.instructor_id.push(e.data.errors.instructor_id[0]);
                                                        if (e.data.errors.week) this.$refs.formModalValidate.errors.week.push(e.data.errors.week[0]);
                                                    }
                                                    else if (e.status == 406) {
                                                        this.$toast.error(this.$t('api.' + error.data.message));
                                                    }
                                                })
                                                .finally(() => {
                                                    this.formProcess = false;
                                                })
                    }
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }

            },

            // Delete
            deleteForm(id) {
                if(this.checkPermission('sectioninstructor_delete')){
                    this.$swal.fire({
                        text: this.$t('are_you_sure_to_delete'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('yes'),
                        cancelButtonText: this.$t('no'),
                    })
                        .then((result) => {
                            if (result.isConfirmed) {
                                SectionInstructorService.del(id)
                                                        .then(response => {
                                                            this.$toast.success(this.$t('api.' + response.data.message));
                                                            this.getInstructors(this.section.id);
                                                        })
                                                        .catch(error => {
                                                            this.$toast.error(this.$t('api.' + error.data.message));
                                                        });
                            }
                        })
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }

            }
        }
    }
</script>

